<template>
  <div>
    <b-card bg-variant="Default">
      <!-- table -->
      <vue-good-table
      mode="remote"
      ref="table"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      :totalRows="totalRecords"
      :rows="rows"
      :row-style-class="trBackgroundColor"
      :columns="columns"
      :isLoading=isLoading
      :search-options="{ 
          enabled: true,
          externalQuery: searchTerm }"
      :pagination-options="{
          enabled: true,
          perPage:pageLength
      }"
      >
        
        <template slot="loadingContent">
          <div class="text-center my-3">
              <b-spinner
                variant="primary"
                style="width: 3rem; height: 3rem;"
                label="loading"
              />
              <div class="d-block pt-1 text-primary">Caricamento</div>
          </div>
        </template>
        
        <div slot="emptystate"> <div class="text-center py-4"> Caricamento Log in corso ...</div> </div>

        <template
            slot="table-row"
            slot-scope="props"
        >

            <!-- Column: created_at -->
            <div v-if="props.column.field === 'created_at'">
              {{ formattazioneDataOra(props.row.created_at, 'it') }}
            </div>

            <!-- Column: message -->
            <div v-else-if="props.column.field === 'message'">
              {{ props.row.message | truncate(60) }}
            </div>

            <!-- Column: azioni -->
            <span v-else-if="props.column.field === 'azioni'">
              <div class="text-nowrap">
                <feather-icon
                  icon="ArrowRightIcon"
                  class="cursor-pointer text-primary"
                  size="21"
                  v-b-tooltip.hover.v-primary
                  title="scheda log"
                  :id="`log-riga-${props.row.id}-azioni`"
                  @click="$router.push({ name: 'tools-log-show', params: { id_riga: props.row.id }})"
                />
              </div>
            </span>

            <!-- Column: standard -->
            <span v-else>
            {{ props.formattedRow[props.column.field] }}
            </span>
        </template>

        <!-- pagination -->
        <template
            slot="pagination-bottom"
            slot-scope="props"
        >
            <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                Visualizzazione da 1 a 
                </span>
                <b-form-select
                v-model="pageLength"
                :options="['10','25','50','100']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> di {{ props.total }} righe </span>
            </div>
            <div>
                <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
                >
                <template #prev-text>
                    <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                    />
                </template>
                <template #next-text>
                    <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                    />
                </template>
                </b-pagination>
            </div>
            </div>
        </template>
      </vue-good-table>
            
    </b-card>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BCardText, BRow, BCol, BBadge, BPagination, BSpinner, BFormGroup, BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BDropdownDivider, BFormSelect, BDropdown, BDropdownItem, BButton, BSidebar, VBToggle, VBTooltip
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BCard, 
    BCardText,
    BRow, 
    BCol, 
    BBadge,
    BPagination,
    BSpinner,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BDropdownDivider,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BButton,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      isLoading: true,
      emptystate: true,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Notifica',
          field: 'level',
          width: '170px',
          filterOptions: {
            enabled: true,
            placeholder: 'Tutti', 
            filterDropdownItems: ['debug', 'info', 'notice', 'warning', 'error', 'critical', 'alert', 'emergency'],          
          },
        },
        {
          label: 'Data - Ora',
          field: 'created_at',
          /*
          type: 'date',
          inputFormat: 'YYYY-MM-dd\'T\'HH:mm:ss.SSSSSSXXX',
          outputFormat: 'YYYY-MM-dd\'T\'HH:mm:ss.SSSSSSXXX',
          */
          width: '170px',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
          },
        },
        {
          label: 'Messaggio',
          field: 'message',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
          },
        },
        {
          label: '',
          field: 'azioni',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
      rows: [],
      searchTerm: '',
      resetState: 'hidden',
      totalRecords: 0,
      serverParams: {
        columnFilters: {
        },
        sort: [
            {
            field: 'created_at',
            type: 'desc'
            }
        ],
        page: 1, 
        perPage: 10
      },
      startServerParams: {
        columnFilters: {
        },
        sort: [
            {
            field: 'created_at',
            type: 'desc'
            }
        ],
        page: 1, 
        perPage: 10
      },
      options_ricerca: [
        { value: null, text: 'seleziona...' },
        { value: 'name', text: 'Nome' },
        /*
        { value: 'surname', text: 'Cognome' },
        { value: 'email', text: 'Email' },
        { value: 'mobile_phone', text: 'Cellulare' },
        { value: 'roles.name', text: 'Ruolo' },
        { value: 'billing.region.name', text: 'Regione' },
        { value: 'billing.province.name', text: 'Provincia' },
        */
      ],
      campiRicerca: {
        nome: '',
        sezione: null,
      },
    }
  },
  computed: {

  },
  created() {
    //console.log("-----------------------")
    //console.log("speriamo bene ... "+moment().locale('it').format('dddd'))

    this.loadItems();
  },
  filters: {
    truncate: function(value,size){
      if (!value) return '';
      value = value.toString();

      if (value.length <= size) {
        return value;
      }
      return value.substr(0, size) + '...';
    }
  },
  methods: {
    formattazioneDataOra(created_at,lang) {
      if (created_at) {
        return moment(String(created_at)).locale(lang).format('DD/MM/YYYY - HH:mm:ss')
      } else {
        return '---'
      }
    },
    trBackgroundColor(row) {
      if ((row.level == 'error') || (row.level == 'critical') || (row.level == 'alert') || (row.level == 'emergency')) {
        return 'table-danger';
      } else if ((row.level == 'warning')) {
        return 'table-warning';
      } else {
        return '';
      }
    },
    advanceFilter(val) {
      //this.searchTerm = val

      this.updateParams(val);
      this.loadItems();
    },
    
    updateParams(newProps) {
      console.log(newProps)

      if (typeof newProps.columnFilters !== 'undefined'){
        if(typeof newProps.columnFilters.aggregato_name_surname !== 'undefined'){
          //in caso di campo con "stringa" => vai di filtro per il valore richiesto
          if(newProps.columnFilters.aggregato_name_surname != ''){
            newProps.columnFilters.surname = newProps.columnFilters.aggregato_name_surname;
          }

          //in caso di campo in cui avevo impostato un filtro e lo voglio cancellare
          if(newProps.columnFilters.aggregato_name_surname == ''){
            delete(newProps.columnFilters.surname);
          }
        }

      }

      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    
    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({perPage: params.currentPerPage});
      this.loadItems();
    },

    onSortChange(params) {
      console.log('cambio ordinamento')
      console.log(params)

      let field_ricerca = '';

      /*
      //colonne con relazione
      if(params[0].field == 'role'){
        field_ricerca = 'roles.name';
      } else if(params[0].field == 'aggregato_name_surname'){
        field_ricerca = 'surname';
      } else {
        field_ricerca = params[0].field;
      }
      */

      this.updateParams({
        sort: [{
          type: params[0].type,
          field: field_ricerca,
        }],
      });
      this.loadItems();
    },
    
    onColumnFilter(params) {
      console.log("AGGIORNAMENTO FILTRO COLONNA --------")
      this.updateParams(params);
      this.loadItems();
    },

    loadItems() {
      this.isLoading = true;

      if (typeof this.serverParams.columnFilters.aggregato_name_surname !== 'undefined'){
        //console.log("cancella campo -> this.serverParams.columnFilters.aggregato_name_surname")
        var temporaneo_aggregato_name_surname = this.serverParams.columnFilters.aggregato_name_surname;
        delete(this.serverParams.columnFilters.aggregato_name_surname);

        //console.log("temporaneo_aggregato_name_surname -> "+temporaneo_aggregato_name_surname)
      }

      this.$http.get('api/crm/log/list', {
        params: this.serverParams 
        }).then(response => { 
            /*
            console.log("risposta vvv"); 
            console.log(response.data)
            console.log('--- ---')
            console.dir(response.data.reply.data)
            console.log("response.data.reply.meta.total -> "+response.data.reply.meta.total);
            */
            
            if(response.data.statusCode == 200){
              //ok

              //popolazione select filtro "Ruolo"
              //this.columns[3].filterOptions.filterDropdownItems = response.data.reply.data[0].frontendSelects.role

              this.totalRecords = response.data.reply.meta.total;
              this.rows = response.data.reply.data;

              //bind colonna "aggregato_name_surname" fix
              if (temporaneo_aggregato_name_surname !== 'undefined'){
                this.serverParams.columnFilters.aggregato_name_surname = temporaneo_aggregato_name_surname;
              }
            } else if(response.data.statusCode == 422){
              //errori con testo di notifica ok dall'api
              alert("messaggio -> "+response.data.reply)
              this.$swal({
                title: 'Errore',
                text: 'codice errore: '+response.data.reply,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  textButton: 'Chiudi',
                },
                buttonsStyling: false,
              })
            } else {
              //tutto gli altri possibili errori
              this.$swal({
                title: 'Errore Generico',
                text: 'codice errore: '+response.data.statusCode,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  textButton: 'Chiudi',
                },
                buttonsStyling: false,
              })
            }
            
            //rimuovi il loading dopo il caricamento dei dati nella tabella
            this.isLoading = false;
        
        })

    },
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>